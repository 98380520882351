import { useEffect, useState } from "react";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { IBaseList } from "../../core/entities/BaseList";
import { DeleteRequest, processDeleteAsync } from "../../core/services/DeleteRequest";
import { processPutAsync, PutRequest } from "../../core/services/PutRequest";
import { defaultPaginationFilter, IUserPaginationFilter } from "../entities/User";
import { defaultReference, getReferenceForSave, IReference } from "../entities/Reference";

export const useCreateReference = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [reference, setReference] = useState<IReference>(defaultReference);
 
    useEffect(() => {
        if (reference.title === '') { return; }
        processPostAsync(setService, `api/app/reference-items`, 
            { title: reference.title, definition: reference.definition, sortOrder: Number(reference.sortOrder), url: reference.url});
        setReference(defaultReference);
    }, [reference]);

    return { service, setReference };
};

export const usePutReference = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [reference, setReference] = useState<IReference>(defaultReference);

    useEffect(() => {
        if (reference.id === '') { return; }
        processPutAsync(setService, `/api/app/reference-items/${reference.id}`, getReferenceForSave(reference));
        setReference(defaultReference);
    }, [reference]);

    return { service, setReference };
};

export const useGetReference = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReference>>());
    const [params, setParams] = useState<IUserPaginationFilter>(defaultPaginationFilter);

    useEffect(() => {
        let url = `api/app/reference-items?&MaxResultCount=1000`;
        if (params.filter && params.filter.length) {
            url += `&FilterText=${params.filter}`;
        }
        processGetAsync(setService, url);
        setParams(params);
    }, [params]);

    return { service, setParams };
};

export const useDeleteReference = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [deleteId, setDeleteId] = useState<number>(0);

    useEffect(() => {
        if (deleteId < 1) { return; }
        processDeleteAsync(setService, `/api/app/reference-items/${deleteId}`);
    }, [deleteId]);

    return { service, setDeleteId };
};

export const getReferenceList = (i: IBaseList<IReference>): IReference[] => {
    const d: IReference[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultReference,
            ...v
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};